
export default {
  components: {
    DownloadAppModal: () => import(/* webpackPrefetch: true */ '@/components/booking/download-app-modal'),
    InstantBookConfirmModal: () => import(/* webpackPrefetch: true */ '@/components/modals/instant-book-confirm'),
    BookingPaymentDetailsModal: () => import(/* webpackPrefetch: true */ '@/components/booking/payment/details-modal'),
    BookCallModdal: () => import('@/components/modals/book-call-modal')
  },

  computed: {
    visible() {
      return this.$store.state.modals.isVisible
    },

    modal() {
      return this.$store.getters['modals/modalShowing']?.name
    },

    data() {
      return this.$store.getters['modals/modalData']
    },

    onClose() {
      return this.$store.getters['modals/onClose']
    }
  },

  methods: {
    moveNext(data) {
      if (this.onClose) this.onClose(data)
      this.$store.dispatch('modals/SHOW_NEXT_MODAL')
    }
  }
}
