import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=1180d154"
import script from "./simple.vue?vue&type=script&lang=js"
export * from "./simple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./simple.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZNavItem: require('/azp/_work/1/s/components/style-guide/nav-item.vue').default,ZNavText: require('/azp/_work/1/s/components/style-guide/nav-text.vue').default,ZNavbarNav: require('/azp/_work/1/s/components/style-guide/navbar-nav.vue').default,Footer: require('/azp/_work/1/s/components/app/footer.vue').default})
