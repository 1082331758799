import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _427e3570 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4c9c61b6 = () => interopDefault(import('../pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _070ee721 = () => interopDefault(import('../pages/app-download.vue' /* webpackChunkName: "pages/app-download" */))
const _730c6d52 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _cdad9688 = () => interopDefault(import('../pages/callback-oauth.vue' /* webpackChunkName: "pages/callback-oauth" */))
const _6a42dfd8 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _37ce2733 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _212d14ba = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5a972d9e = () => interopDefault(import('../pages/destination.vue' /* webpackChunkName: "pages/destination" */))
const _16008539 = () => interopDefault(import('../pages/experiments.vue' /* webpackChunkName: "pages/experiments" */))
const _62361035 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _724ada6c = () => interopDefault(import('../pages/index/signin.vue' /* webpackChunkName: "pages/index/signin" */))
const _f0cf6758 = () => interopDefault(import('../pages/index/logout.vue' /* webpackChunkName: "pages/index/logout" */))
const _f23d9e3c = () => interopDefault(import('../pages/index/signup.vue' /* webpackChunkName: "pages/index/signup" */))
const _d4cfe5ac = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _17dd69cf = () => interopDefault(import('../pages/insurance-and-protection.vue' /* webpackChunkName: "pages/insurance-and-protection" */))
const _d935fcd4 = () => interopDefault(import('../pages/owner.vue' /* webpackChunkName: "pages/owner" */))
const _5dc3abce = () => interopDefault(import('../pages/payment-callback.vue' /* webpackChunkName: "pages/payment-callback" */))
const _31c718c4 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _a364bb8e = () => interopDefault(import('../pages/recovery-password/index.vue' /* webpackChunkName: "pages/recovery-password/index" */))
const _35c241e4 = () => interopDefault(import('../pages/refer-a-friend.vue' /* webpackChunkName: "pages/refer-a-friend" */))
const _6475ab6e = () => interopDefault(import('../pages/refer-a-host.vue' /* webpackChunkName: "pages/refer-a-host" */))
const _24246f84 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _71614300 = () => interopDefault(import('../pages/rv-delivery.vue' /* webpackChunkName: "pages/rv-delivery" */))
const _d5bba41a = () => interopDefault(import('../pages/rv-rentals.vue' /* webpackChunkName: "pages/rv-rentals" */))
const _0d48c098 = () => interopDefault(import('../pages/rv-search.vue' /* webpackChunkName: "pages/rv-search" */))
const _a2db0c9c = () => interopDefault(import('../pages/service-fees.vue' /* webpackChunkName: "pages/service-fees" */))
const _361e6334 = () => interopDefault(import('../pages/share-your-trip.vue' /* webpackChunkName: "pages/share-your-trip" */))
const _57e96906 = () => interopDefault(import('../pages/superhost.vue' /* webpackChunkName: "pages/superhost" */))
const _5e2d5252 = () => interopDefault(import('../pages/terms-service.vue' /* webpackChunkName: "pages/terms-service" */))
const _74fb50ec = () => interopDefault(import('../pages/trust-and-safety.vue' /* webpackChunkName: "pages/trust-and-safety" */))
const _ed879d38 = () => interopDefault(import('../pages/careers-data-analyst.vue' /* webpackChunkName: "pages/careers-data-analyst" */))
const _d500f498 = () => interopDefault(import('../pages/careers-owner-success.vue' /* webpackChunkName: "pages/careers-owner-success" */))
const _e73b36bc = () => interopDefault(import('../pages/careers-trust-and-safety.vue' /* webpackChunkName: "pages/careers-trust-and-safety" */))
const _2ec95cf6 = () => interopDefault(import('../pages/dashboard/bookings/index.vue' /* webpackChunkName: "pages/dashboard/bookings/index" */))
const _3083e048 = () => interopDefault(import('../pages/dashboard/bookings/index/index.vue' /* webpackChunkName: "pages/dashboard/bookings/index/index" */))
const _d713ab7e = () => interopDefault(import('../pages/dashboard/bookings/index/completed.vue' /* webpackChunkName: "pages/dashboard/bookings/index/completed" */))
const _945a6556 = () => interopDefault(import('../pages/dashboard/bookings/index/confirmed.vue' /* webpackChunkName: "pages/dashboard/bookings/index/confirmed" */))
const _4b2b155b = () => interopDefault(import('../pages/dashboard/bookings/index/ezymatch.vue' /* webpackChunkName: "pages/dashboard/bookings/index/ezymatch" */))
const _5049570d = () => interopDefault(import('../pages/dashboard/bookings/index/pending.vue' /* webpackChunkName: "pages/dashboard/bookings/index/pending" */))
const _d15deaf4 = () => interopDefault(import('../pages/dashboard/calendar.vue' /* webpackChunkName: "pages/dashboard/calendar" */))
const _ca50bb9c = () => interopDefault(import('../pages/dashboard/favourites.vue' /* webpackChunkName: "pages/dashboard/favourites" */))
const _7116b0a3 = () => interopDefault(import('../pages/dashboard/insights.vue' /* webpackChunkName: "pages/dashboard/insights" */))
const _14c4b592 = () => interopDefault(import('../pages/dashboard/listings.vue' /* webpackChunkName: "pages/dashboard/listings" */))
const _60b95994 = () => interopDefault(import('../pages/dashboard/messages.vue' /* webpackChunkName: "pages/dashboard/messages" */))
const _350d3dd2 = () => interopDefault(import('../pages/dashboard/messages/index.vue' /* webpackChunkName: "pages/dashboard/messages/index" */))
const _98279f88 = () => interopDefault(import('../pages/dashboard/messages/archives.vue' /* webpackChunkName: "pages/dashboard/messages/archives" */))
const _10f7e2e6 = () => interopDefault(import('../pages/dashboard/messages/enquiries.vue' /* webpackChunkName: "pages/dashboard/messages/enquiries" */))
const _65fa368f = () => interopDefault(import('../pages/dashboard/messages/requests.vue' /* webpackChunkName: "pages/dashboard/messages/requests" */))
const _456a8582 = () => interopDefault(import('../pages/dashboard/messages/_id.vue' /* webpackChunkName: "pages/dashboard/messages/_id" */))
const _f89a43de = () => interopDefault(import('../pages/dashboard/profile.vue' /* webpackChunkName: "pages/dashboard/profile" */))
const _96aa5858 = () => interopDefault(import('../pages/dashboard/profile/index.vue' /* webpackChunkName: "pages/dashboard/profile/index" */))
const _6fe6814d = () => interopDefault(import('../pages/dashboard/profile/drivers.vue' /* webpackChunkName: "pages/dashboard/profile/drivers" */))
const _6c4e1a10 = () => interopDefault(import('../pages/dashboard/profile/drivers/index.vue' /* webpackChunkName: "pages/dashboard/profile/drivers/index" */))
const _5d1d9544 = () => interopDefault(import('../pages/dashboard/profile/drivers/new.vue' /* webpackChunkName: "pages/dashboard/profile/drivers/new" */))
const _a893e2aa = () => interopDefault(import('../pages/dashboard/profile/features.vue' /* webpackChunkName: "pages/dashboard/profile/features" */))
const _60fbe7c2 = () => interopDefault(import('../pages/dashboard/profile/goals.vue' /* webpackChunkName: "pages/dashboard/profile/goals" */))
const _87f92716 = () => interopDefault(import('../pages/dashboard/profile/message-templates.vue' /* webpackChunkName: "pages/dashboard/profile/message-templates" */))
const _103b3aea = () => interopDefault(import('../pages/dashboard/profile/notifications.vue' /* webpackChunkName: "pages/dashboard/profile/notifications" */))
const _6f551ef0 = () => interopDefault(import('../pages/dashboard/profile/payment.vue' /* webpackChunkName: "pages/dashboard/profile/payment" */))
const _c76b6dd8 = () => interopDefault(import('../pages/dashboard/profile/payout.vue' /* webpackChunkName: "pages/dashboard/profile/payout" */))
const _62c461e1 = () => interopDefault(import('../pages/dashboard/profile/_.vue' /* webpackChunkName: "pages/dashboard/profile/_" */))
const _3aa08ed6 = () => interopDefault(import('../pages/dashboard/trips.vue' /* webpackChunkName: "pages/dashboard/trips" */))
const _787fc64e = () => interopDefault(import('../pages/dashboard/trips/index.vue' /* webpackChunkName: "pages/dashboard/trips/index" */))
const _7f2fb652 = () => interopDefault(import('../pages/dashboard/trips/completed.vue' /* webpackChunkName: "pages/dashboard/trips/completed" */))
const _0568b35e = () => interopDefault(import('../pages/dashboard/trips/pending.vue' /* webpackChunkName: "pages/dashboard/trips/pending" */))
const _25c7ad21 = () => interopDefault(import('../pages/dashboard/rvs/deleted.vue' /* webpackChunkName: "pages/dashboard/rvs/deleted" */))
const _7bde0328 = () => interopDefault(import('../pages/dashboard/rvs/new.vue' /* webpackChunkName: "pages/dashboard/rvs/new" */))
const _47a50187 = () => interopDefault(import('../pages/dashboard/rvs/new/_step/index.vue' /* webpackChunkName: "pages/dashboard/rvs/new/_step/index" */))
const _75bd4596 = () => interopDefault(import('../pages/dashboard/rvs/new/_step/_field.vue' /* webpackChunkName: "pages/dashboard/rvs/new/_step/_field" */))
const _4ac922cc = () => interopDefault(import('../pages/dashboard/rvs/phone-number-required.vue' /* webpackChunkName: "pages/dashboard/rvs/phone-number-required" */))
const _0a3f5321 = () => interopDefault(import('../pages/dashboard/rvs/published/_id.vue' /* webpackChunkName: "pages/dashboard/rvs/published/_id" */))
const _16b621f0 = () => interopDefault(import('../pages/dashboard/bookings/_id/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index" */))
const _6d2692b7 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/accept.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/accept" */))
const _1a89ac16 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/accepted.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/accepted" */))
const _26db0329 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/cancel.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/cancel" */))
const _dbace976 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/closeout.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/closeout" */))
const _6f1924a8 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/complete.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/complete" */))
const _a7b62ce2 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/custom-quote.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/custom-quote" */))
const _3c729e57 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/decline.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/decline" */))
const _c8f74788 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/index" */))
const _103ae192 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/full-review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/full-review" */))
const _3fbcaa1e = () => interopDefault(import('../pages/dashboard/bookings/_id/index/income-protection.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/income-protection" */))
const _3b36fc0a = () => interopDefault(import('../pages/dashboard/bookings/_id/index/insurance.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/insurance" */))
const _6ba8c7b9 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/addons.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/addons" */))
const _8447af22 = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/dates.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/dates" */))
const _4198c12c = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/details.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/details" */))
const _05cab3fe = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/pending.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/pending" */))
const _3a1a3e5e = () => interopDefault(import('../pages/dashboard/bookings/_id/index/edit/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/index/edit/review" */))
const _4535e8e6 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id" */))
const _ce681760 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/index.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/index" */))
const _17543225 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/addons.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/addons" */))
const _0ed409a0 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/calendar.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/calendar" */))
const _0b5aa440 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/details.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/details" */))
const _17aec6b0 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/earn-more.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/earn-more" */))
const _1e85cb58 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/insurance.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/insurance" */))
const _39947d73 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/photos.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/photos" */))
const _211eba14 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/preferences.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/preferences" */))
const _3d402078 = () => interopDefault(import('../pages/dashboard/rvs/_rv-Id/pricing.vue' /* webpackChunkName: "pages/dashboard/rvs/_rv-Id/pricing" */))
const _62a12cd2 = () => interopDefault(import('../pages/rv-rental/request-to-book/_alias.vue' /* webpackChunkName: "pages/rv-rental/request-to-book/_alias" */))
const _7be60e22 = () => interopDefault(import('../pages/rv-rental/request-to-book/_alias/additionals.vue' /* webpackChunkName: "pages/rv-rental/request-to-book/_alias/additionals" */))
const _2585bc28 = () => interopDefault(import('../pages/dashboard/bookings/_id/agreement.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/agreement" */))
const _cde2f6aa = () => interopDefault(import('../pages/dashboard/bookings/_id/agreement/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/agreement/index" */))
const _4656ba3e = () => interopDefault(import('../pages/dashboard/bookings/_id/agreement/error.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/agreement/error" */))
const _0afd8911 = () => interopDefault(import('../pages/dashboard/bookings/_id/agreement/signature.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/agreement/signature" */))
const _32c80f1c = () => interopDefault(import('../pages/dashboard/bookings/_id/agreement/success.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/agreement/success" */))
const _7ad3fa44 = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request" */))
const _71368472 = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/index" */))
const _9baf183a = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/accept.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/accept" */))
const _d0a63aaa = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/decline.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/decline" */))
const _eb73e39a = () => interopDefault(import('../pages/dashboard/bookings/_id/change-request/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/change-request/review" */))
const _71a08616 = () => interopDefault(import('../pages/dashboard/bookings/_id/insurance-efnol.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/insurance-efnol" */))
const _0596656a = () => interopDefault(import('../pages/dashboard/bookings/_id/invoice.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/invoice" */))
const _5674b0b8 = () => interopDefault(import('../pages/dashboard/bookings/_id/payment.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/payment" */))
const _d417f902 = () => interopDefault(import('../pages/dashboard/bookings/_id/retry-payment.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/retry-payment" */))
const _25212f2a = () => interopDefault(import('../pages/dashboard/bookings/_id/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/review" */))
const _ed296e62 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money" */))
const _27cbb812 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/index.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/index" */))
const _97908df8 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/dispute.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/dispute" */))
const _d1a8dd62 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/request.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/request" */))
const _36be5d48 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/review.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/review" */))
const _02cd9378 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/send.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/send" */))
const _03af0b88 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/sent.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/sent" */))
const _070604c3 = () => interopDefault(import('../pages/dashboard/bookings/_id/send-request-money/success.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/send-request-money/success" */))
const _82482458 = () => interopDefault(import('../pages/dashboard/bookings/_id/update-payment.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/update-payment" */))
const _5f2fa938 = () => interopDefault(import('../pages/dashboard/bookings/_id/services/update.vue' /* webpackChunkName: "pages/dashboard/bookings/_id/services/update" */))
const _7db118d8 = () => interopDefault(import('../pages/short-link.vue' /* webpackChunkName: "pages/short-link" */))
const _457d7bb3 = () => interopDefault(import('../pages/rv-rental/index.vue' /* webpackChunkName: "pages/rv-rental/index" */))
const _74ea7fba = () => interopDefault(import('../pages/profile/_id/index.vue' /* webpackChunkName: "pages/profile/_id/index" */))
const _9fcf599a = () => interopDefault(import('../pages/recovery-password/_code.vue' /* webpackChunkName: "pages/recovery-password/_code" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _427e3570,
    name: "about___en"
  }, {
    path: "/affiliates",
    component: _4c9c61b6,
    name: "affiliates___en"
  }, {
    path: "/app-download",
    component: _070ee721,
    name: "app-download___en"
  }, {
    path: "/blog",
    component: _730c6d52,
    name: "blog___en"
  }, {
    path: "/callback-oauth",
    component: _cdad9688,
    name: "callback-oauth___en"
  }, {
    path: "/careers",
    component: _6a42dfd8,
    name: "careers___en"
  }, {
    path: "/checkout",
    component: _37ce2733,
    name: "checkout___en"
  }, {
    path: "/dashboard",
    component: _212d14ba,
    name: "dashboard___en"
  }, {
    path: "/destination",
    component: _5a972d9e,
    name: "destination___en"
  }, {
    path: "/experiments",
    component: _16008539,
    name: "experiments___en"
  }, {
    path: "/fr",
    component: _62361035,
    name: "index___fr",
    children: [{
      path: "/fr/connexion",
      component: _724ada6c,
      name: "index-signin___fr"
    }, {
      path: "/fr/deconnexion",
      component: _f0cf6758,
      name: "index-logout___fr"
    }, {
      path: "/fr/inscription",
      component: _f23d9e3c,
      name: "index-signup___fr"
    }]
  }, {
    path: "/how-it-works",
    component: _d4cfe5ac,
    name: "how-it-works___en"
  }, {
    path: "/insurance-and-protection",
    component: _17dd69cf,
    name: "insurance-and-protection___en"
  }, {
    path: "/owner",
    component: _d935fcd4,
    name: "owner___en"
  }, {
    path: "/payment-callback",
    component: _5dc3abce,
    name: "payment-callback___en"
  }, {
    path: "/privacy-policy",
    component: _31c718c4,
    name: "privacy-policy___en"
  }, {
    path: "/recovery-password",
    component: _a364bb8e,
    name: "recovery-password___en"
  }, {
    path: "/refer-a-friend",
    component: _35c241e4,
    name: "refer-a-friend___en"
  }, {
    path: "/refer-a-host",
    component: _6475ab6e,
    name: "refer-a-host___en"
  }, {
    path: "/reviews",
    component: _24246f84,
    name: "reviews___en"
  }, {
    path: "/rv-delivery",
    component: _71614300,
    name: "rv-delivery___en"
  }, {
    path: "/rv-rentals",
    component: _d5bba41a,
    name: "rv-rentals___en"
  }, {
    path: "/rv-search",
    component: _0d48c098,
    name: "rv-search___en"
  }, {
    path: "/service-fees",
    component: _a2db0c9c,
    name: "service-fees___en"
  }, {
    path: "/share-your-trip",
    component: _361e6334,
    name: "share-your-trip___en"
  }, {
    path: "/superhost",
    component: _57e96906,
    name: "superhost___en"
  }, {
    path: "/terms-service",
    component: _5e2d5252,
    name: "terms-service___en"
  }, {
    path: "/trust-and-safety",
    component: _74fb50ec,
    name: "trust-and-safety___en"
  }, {
    path: "/careers/spotlight-data-analyst",
    component: _ed879d38,
    name: "careers-data-analyst___en"
  }, {
    path: "/careers/spotlight-owner-success",
    component: _d500f498,
    name: "careers-owner-success___en"
  }, {
    path: "/careers/spotlight-trust-and-safety",
    component: _e73b36bc,
    name: "careers-trust-and-safety___en"
  }, {
    path: "/dashboard/bookings",
    component: _2ec95cf6,
    children: [{
      path: "",
      component: _3083e048,
      name: "dashboard-bookings-index___en"
    }, {
      path: "completed",
      component: _d713ab7e,
      name: "dashboard-bookings-index-completed___en"
    }, {
      path: "confirmed",
      component: _945a6556,
      name: "dashboard-bookings-index-confirmed___en"
    }, {
      path: "ezymatch",
      component: _4b2b155b,
      name: "dashboard-bookings-index-ezymatch___en"
    }, {
      path: "pending",
      component: _5049570d,
      name: "dashboard-bookings-index-pending___en"
    }]
  }, {
    path: "/dashboard/calendar",
    component: _d15deaf4,
    name: "dashboard-calendar___en"
  }, {
    path: "/dashboard/favourites",
    component: _ca50bb9c,
    name: "dashboard-favourites___en"
  }, {
    path: "/dashboard/insights",
    component: _7116b0a3,
    name: "dashboard-insights___en"
  }, {
    path: "/dashboard/listings",
    component: _14c4b592,
    name: "dashboard-listings___en"
  }, {
    path: "/dashboard/messages",
    component: _60b95994,
    children: [{
      path: "",
      component: _350d3dd2,
      name: "dashboard-messages___en"
    }, {
      path: "archives",
      component: _98279f88,
      name: "dashboard-messages-archives___en"
    }, {
      path: "enquiries",
      component: _10f7e2e6,
      name: "dashboard-messages-enquiries___en"
    }, {
      path: "requests",
      component: _65fa368f,
      name: "dashboard-messages-requests___en"
    }, {
      path: ":id",
      component: _456a8582,
      name: "dashboard-messages-id___en"
    }]
  }, {
    path: "/dashboard/profile",
    component: _f89a43de,
    children: [{
      path: "",
      component: _96aa5858,
      name: "dashboard-profile___en"
    }, {
      path: "drivers",
      component: _6fe6814d,
      children: [{
        path: "",
        component: _6c4e1a10,
        name: "dashboard-profile-drivers___en"
      }, {
        path: "new",
        component: _5d1d9544,
        name: "dashboard-profile-drivers-new___en"
      }]
    }, {
      path: "features",
      component: _a893e2aa,
      name: "dashboard-profile-features___en"
    }, {
      path: "goals",
      component: _60fbe7c2,
      name: "dashboard-profile-goals___en"
    }, {
      path: "message-templates",
      component: _87f92716,
      name: "dashboard-profile-message-templates___en"
    }, {
      path: "notifications",
      component: _103b3aea,
      name: "dashboard-profile-notifications___en"
    }, {
      path: "payment",
      component: _6f551ef0,
      name: "dashboard-profile-payment___en"
    }, {
      path: "payout",
      component: _c76b6dd8,
      name: "dashboard-profile-payout___en"
    }, {
      path: "*",
      component: _62c461e1,
      name: "dashboard-profile-all___en"
    }]
  }, {
    path: "/dashboard/trips",
    component: _3aa08ed6,
    children: [{
      path: "",
      component: _787fc64e,
      name: "dashboard-trips___en"
    }, {
      path: "completed",
      component: _7f2fb652,
      name: "dashboard-trips-completed___en"
    }, {
      path: "pending",
      component: _0568b35e,
      name: "dashboard-trips-pending___en"
    }]
  }, {
    path: "/fr/a-propos",
    component: _427e3570,
    name: "about___fr"
  }, {
    path: "/fr/affilies",
    component: _4c9c61b6,
    name: "affiliates___fr"
  }, {
    path: "/fr/assurances-et-protection",
    component: _17dd69cf,
    name: "insurance-and-protection___fr"
  }, {
    path: "/fr/blogue",
    component: _730c6d52,
    name: "blog___fr"
  }, {
    path: "/fr/callback-oauth",
    component: _cdad9688,
    name: "callback-oauth___fr"
  }, {
    path: "/fr/careers",
    component: _6a42dfd8,
    name: "careers___fr"
  }, {
    path: "/fr/careers-data-analyst",
    component: _ed879d38,
    name: "careers-data-analyst___fr"
  }, {
    path: "/fr/careers-owner-success",
    component: _d500f498,
    name: "careers-owner-success___fr"
  }, {
    path: "/fr/careers-trust-and-safety",
    component: _e73b36bc,
    name: "careers-trust-and-safety___fr"
  }, {
    path: "/fr/checkout",
    component: _37ce2733,
    name: "checkout___fr"
  }, {
    path: "/fr/comment-ca-fonctionne",
    component: _d4cfe5ac,
    name: "how-it-works___fr"
  }, {
    path: "/fr/confiance-et-securite",
    component: _74fb50ec,
    name: "trust-and-safety___fr"
  }, {
    path: "/fr/dashboard",
    component: _212d14ba,
    name: "dashboard___fr"
  }, {
    path: "/fr/destination",
    component: _5a972d9e,
    name: "destination___fr"
  }, {
    path: "/fr/evaluations",
    component: _24246f84,
    name: "reviews___fr"
  }, {
    path: "/fr/experiments",
    component: _16008539,
    name: "experiments___fr"
  }, {
    path: "/fr/frais-de-service",
    component: _a2db0c9c,
    name: "service-fees___fr"
  }, {
    path: "/fr/livraison-vr",
    component: _71614300,
    name: "rv-delivery___fr"
  }, {
    path: "/fr/locations-vr",
    component: _d5bba41a,
    name: "rv-rentals___fr"
  }, {
    path: "/fr/owner",
    component: _d935fcd4,
    name: "owner___fr"
  }, {
    path: "/fr/partagez-votre-voyage",
    component: _361e6334,
    name: "share-your-trip___fr"
  }, {
    path: "/fr/payment-callback",
    component: _5dc3abce,
    name: "payment-callback___fr"
  }, {
    path: "/fr/politique-de-confidentialite",
    component: _31c718c4,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/recherche-vr",
    component: _0d48c098,
    name: "rv-search___fr"
  }, {
    path: "/fr/recovery-password",
    component: _a364bb8e,
    name: "recovery-password___fr"
  }, {
    path: "/fr/referer-un-ami",
    component: _35c241e4,
    name: "refer-a-friend___fr"
  }, {
    path: "/fr/referer-un-hote",
    component: _6475ab6e,
    name: "refer-a-host___fr"
  }, {
    path: "/fr/superhotes",
    component: _57e96906,
    name: "superhost___fr"
  }, {
    path: "/fr/telecharger-application",
    component: _070ee721,
    name: "app-download___fr"
  }, {
    path: "/fr/termes-utilisation",
    component: _5e2d5252,
    name: "terms-service___fr"
  }, {
    path: "/dashboard/rvs/deleted",
    component: _25c7ad21,
    name: "dashboard-rvs-deleted___en"
  }, {
    path: "/dashboard/rvs/new",
    component: _7bde0328,
    name: "dashboard-rvs-new___en",
    children: [{
      path: "/dashboard/rvs/new/:step",
      component: _47a50187,
      name: "dashboard-rvs-new-step___en"
    }, {
      path: "/dashboard/rvs/new/:step/:field",
      component: _75bd4596,
      name: "dashboard-rvs-new-step-field___en"
    }]
  }, {
    path: "/dashboard/rvs/phone-number-required",
    component: _4ac922cc,
    name: "dashboard-rvs-phone-number-required___en"
  }, {
    path: "/fr/dashboard/bookings",
    component: _2ec95cf6,
    children: [{
      path: "",
      component: _3083e048,
      name: "dashboard-bookings-index___fr"
    }, {
      path: "completed",
      component: _d713ab7e,
      name: "dashboard-bookings-index-completed___fr"
    }, {
      path: "confirmed",
      component: _945a6556,
      name: "dashboard-bookings-index-confirmed___fr"
    }, {
      path: "ezymatch",
      component: _4b2b155b,
      name: "dashboard-bookings-index-ezymatch___fr"
    }, {
      path: "pending",
      component: _5049570d,
      name: "dashboard-bookings-index-pending___fr"
    }]
  }, {
    path: "/fr/dashboard/calendar",
    component: _d15deaf4,
    name: "dashboard-calendar___fr"
  }, {
    path: "/fr/dashboard/favourites",
    component: _ca50bb9c,
    name: "dashboard-favourites___fr"
  }, {
    path: "/fr/dashboard/insights",
    component: _7116b0a3,
    name: "dashboard-insights___fr"
  }, {
    path: "/fr/dashboard/listings",
    component: _14c4b592,
    name: "dashboard-listings___fr"
  }, {
    path: "/fr/dashboard/messages",
    component: _60b95994,
    children: [{
      path: "",
      component: _350d3dd2,
      name: "dashboard-messages___fr"
    }, {
      path: "archives",
      component: _98279f88,
      name: "dashboard-messages-archives___fr"
    }, {
      path: "enquiries",
      component: _10f7e2e6,
      name: "dashboard-messages-enquiries___fr"
    }, {
      path: "requests",
      component: _65fa368f,
      name: "dashboard-messages-requests___fr"
    }, {
      path: ":id",
      component: _456a8582,
      name: "dashboard-messages-id___fr"
    }]
  }, {
    path: "/fr/dashboard/profile",
    component: _f89a43de,
    children: [{
      path: "",
      component: _96aa5858,
      name: "dashboard-profile___fr"
    }, {
      path: "drivers",
      component: _6fe6814d,
      children: [{
        path: "",
        component: _6c4e1a10,
        name: "dashboard-profile-drivers___fr"
      }, {
        path: "new",
        component: _5d1d9544,
        name: "dashboard-profile-drivers-new___fr"
      }]
    }, {
      path: "features",
      component: _a893e2aa,
      name: "dashboard-profile-features___fr"
    }, {
      path: "goals",
      component: _60fbe7c2,
      name: "dashboard-profile-goals___fr"
    }, {
      path: "message-templates",
      component: _87f92716,
      name: "dashboard-profile-message-templates___fr"
    }, {
      path: "notifications",
      component: _103b3aea,
      name: "dashboard-profile-notifications___fr"
    }, {
      path: "payment",
      component: _6f551ef0,
      name: "dashboard-profile-payment___fr"
    }, {
      path: "payout",
      component: _c76b6dd8,
      name: "dashboard-profile-payout___fr"
    }, {
      path: "*",
      component: _62c461e1,
      name: "dashboard-profile-all___fr"
    }]
  }, {
    path: "/fr/dashboard/trips",
    component: _3aa08ed6,
    children: [{
      path: "",
      component: _787fc64e,
      name: "dashboard-trips___fr"
    }, {
      path: "completed",
      component: _7f2fb652,
      name: "dashboard-trips-completed___fr"
    }, {
      path: "pending",
      component: _0568b35e,
      name: "dashboard-trips-pending___fr"
    }]
  }, {
    path: "/fr/dashboard/rvs/deleted",
    component: _25c7ad21,
    name: "dashboard-rvs-deleted___fr"
  }, {
    path: "/fr/dashboard/rvs/new",
    component: _7bde0328,
    name: "dashboard-rvs-new___fr",
    children: [{
      path: "/fr/dashboard/rvs/new/:step",
      component: _47a50187,
      name: "dashboard-rvs-new-step___fr"
    }, {
      path: "/fr/dashboard/rvs/new/:step/:field",
      component: _75bd4596,
      name: "dashboard-rvs-new-step-field___fr"
    }]
  }, {
    path: "/fr/dashboard/rvs/phone-number-required",
    component: _4ac922cc,
    name: "dashboard-rvs-phone-number-required___fr"
  }, {
    path: "/",
    component: _62361035,
    name: "index___en",
    children: [{
      path: "/logout",
      component: _f0cf6758,
      name: "index-logout___en"
    }, {
      path: "/signin",
      component: _724ada6c,
      name: "index-signin___en"
    }, {
      path: "/signup",
      component: _f23d9e3c,
      name: "index-signup___en"
    }]
  }, {
    path: "/fr/dashboard/rvs/published/:id",
    component: _0a3f5321,
    name: "dashboard-rvs-published-id___fr"
  }, {
    path: "/dashboard/rvs/published/:id",
    component: _0a3f5321,
    name: "dashboard-rvs-published-id___en"
  }, {
    path: "/fr/dashboard/bookings/:id",
    component: _16b621f0,
    name: "dashboard-bookings-id___fr",
    children: [{
      path: "accept",
      component: _6d2692b7,
      name: "dashboard-bookings-id-index-accept___fr"
    }, {
      path: "accepted",
      component: _1a89ac16,
      name: "dashboard-bookings-id-index-accepted___fr"
    }, {
      path: "cancel",
      component: _26db0329,
      name: "dashboard-bookings-id-index-cancel___fr"
    }, {
      path: "closeout",
      component: _dbace976,
      name: "dashboard-bookings-id-index-closeout___fr"
    }, {
      path: "complete",
      component: _6f1924a8,
      name: "dashboard-bookings-id-index-complete___fr"
    }, {
      path: "custom-quote",
      component: _a7b62ce2,
      name: "dashboard-bookings-id-index-custom-quote___fr"
    }, {
      path: "decline",
      component: _3c729e57,
      name: "dashboard-bookings-id-index-decline___fr"
    }, {
      path: "edit",
      component: _c8f74788,
      name: "dashboard-bookings-id-index-edit___fr"
    }, {
      path: "full-review",
      component: _103ae192,
      name: "dashboard-bookings-id-index-full-review___fr"
    }, {
      path: "income-protection",
      component: _3fbcaa1e,
      name: "dashboard-bookings-id-index-income-protection___fr"
    }, {
      path: "insurance",
      component: _3b36fc0a,
      name: "dashboard-bookings-id-index-insurance___fr"
    }, {
      path: "edit/addons",
      component: _6ba8c7b9,
      name: "dashboard-bookings-id-index-edit-addons___fr"
    }, {
      path: "edit/dates",
      component: _8447af22,
      name: "dashboard-bookings-id-index-edit-dates___fr"
    }, {
      path: "edit/details",
      component: _4198c12c,
      name: "dashboard-bookings-id-index-edit-details___fr"
    }, {
      path: "edit/pending",
      component: _05cab3fe,
      name: "dashboard-bookings-id-index-edit-pending___fr"
    }, {
      path: "edit/review",
      component: _3a1a3e5e,
      name: "dashboard-bookings-id-index-edit-review___fr"
    }]
  }, {
    path: "/fr/dashboard/rvs/:rvID",
    component: _4535e8e6,
    children: [{
      path: "",
      component: _ce681760,
      name: "dashboard-rvs-rv-Id___fr"
    }, {
      path: "addons",
      component: _17543225,
      name: "dashboard-rvs-rv-Id-addons___fr"
    }, {
      path: "calendar",
      component: _0ed409a0,
      name: "dashboard-rvs-rv-Id-calendar___fr"
    }, {
      path: "details",
      component: _0b5aa440,
      name: "dashboard-rvs-rv-Id-details___fr"
    }, {
      path: "earn-more",
      component: _17aec6b0,
      name: "dashboard-rvs-rv-Id-earn-more___fr"
    }, {
      path: "insurance",
      component: _1e85cb58,
      name: "dashboard-rvs-rv-Id-insurance___fr"
    }, {
      path: "photos",
      component: _39947d73,
      name: "dashboard-rvs-rv-Id-photos___fr"
    }, {
      path: "preferences",
      component: _211eba14,
      name: "dashboard-rvs-rv-Id-preferences___fr"
    }, {
      path: "pricing",
      component: _3d402078,
      name: "dashboard-rvs-rv-Id-pricing___fr"
    }]
  }, {
    path: "/fr/location-vr/request-to-book/:alias",
    component: _62a12cd2,
    name: "rv-rental-request-to-book-alias___fr",
    children: [{
      path: "additionals",
      component: _7be60e22,
      name: "rv-rental-request-to-book-alias-additionals___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/agreement",
    component: _2585bc28,
    children: [{
      path: "",
      component: _cde2f6aa,
      name: "dashboard-bookings-id-agreement___fr"
    }, {
      path: "error",
      component: _4656ba3e,
      name: "dashboard-bookings-id-agreement-error___fr"
    }, {
      path: "signature",
      component: _0afd8911,
      name: "dashboard-bookings-id-agreement-signature___fr"
    }, {
      path: "success",
      component: _32c80f1c,
      name: "dashboard-bookings-id-agreement-success___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/change-request",
    component: _7ad3fa44,
    children: [{
      path: "",
      component: _71368472,
      name: "dashboard-bookings-id-change-request___fr"
    }, {
      path: "accept",
      component: _9baf183a,
      name: "dashboard-bookings-id-change-request-accept___fr"
    }, {
      path: "decline",
      component: _d0a63aaa,
      name: "dashboard-bookings-id-change-request-decline___fr"
    }, {
      path: "review",
      component: _eb73e39a,
      name: "dashboard-bookings-id-change-request-review___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/insurance-efnol",
    component: _71a08616,
    name: "dashboard-bookings-id-insurance-efnol___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/invoice",
    component: _0596656a,
    name: "dashboard-bookings-id-invoice___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/payment",
    component: _5674b0b8,
    name: "dashboard-bookings-id-payment___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/retry-payment",
    component: _d417f902,
    name: "dashboard-bookings-id-retry-payment___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/review",
    component: _25212f2a,
    name: "dashboard-bookings-id-review___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/send-request-money",
    component: _ed296e62,
    children: [{
      path: "",
      component: _27cbb812,
      name: "dashboard-bookings-id-send-request-money___fr"
    }, {
      path: "dispute",
      component: _97908df8,
      name: "dashboard-bookings-id-send-request-money-dispute___fr"
    }, {
      path: "request",
      component: _d1a8dd62,
      name: "dashboard-bookings-id-send-request-money-request___fr"
    }, {
      path: "review",
      component: _36be5d48,
      name: "dashboard-bookings-id-send-request-money-review___fr"
    }, {
      path: "send",
      component: _02cd9378,
      name: "dashboard-bookings-id-send-request-money-send___fr"
    }, {
      path: "sent",
      component: _03af0b88,
      name: "dashboard-bookings-id-send-request-money-sent___fr"
    }, {
      path: "success",
      component: _070604c3,
      name: "dashboard-bookings-id-send-request-money-success___fr"
    }]
  }, {
    path: "/fr/dashboard/bookings/:id/update-payment",
    component: _82482458,
    name: "dashboard-bookings-id-update-payment___fr"
  }, {
    path: "/fr/dashboard/bookings/:id/services/update",
    component: _5f2fa938,
    name: "dashboard-bookings-id-services-update___fr"
  }, {
    path: "/dashboard/bookings/:id",
    component: _16b621f0,
    name: "dashboard-bookings-id___en",
    children: [{
      path: "accept",
      component: _6d2692b7,
      name: "dashboard-bookings-id-index-accept___en"
    }, {
      path: "accepted",
      component: _1a89ac16,
      name: "dashboard-bookings-id-index-accepted___en"
    }, {
      path: "cancel",
      component: _26db0329,
      name: "dashboard-bookings-id-index-cancel___en"
    }, {
      path: "closeout",
      component: _dbace976,
      name: "dashboard-bookings-id-index-closeout___en"
    }, {
      path: "complete",
      component: _6f1924a8,
      name: "dashboard-bookings-id-index-complete___en"
    }, {
      path: "custom-quote",
      component: _a7b62ce2,
      name: "dashboard-bookings-id-index-custom-quote___en"
    }, {
      path: "decline",
      component: _3c729e57,
      name: "dashboard-bookings-id-index-decline___en"
    }, {
      path: "edit",
      component: _c8f74788,
      name: "dashboard-bookings-id-index-edit___en"
    }, {
      path: "full-review",
      component: _103ae192,
      name: "dashboard-bookings-id-index-full-review___en"
    }, {
      path: "income-protection",
      component: _3fbcaa1e,
      name: "dashboard-bookings-id-index-income-protection___en"
    }, {
      path: "insurance",
      component: _3b36fc0a,
      name: "dashboard-bookings-id-index-insurance___en"
    }, {
      path: "edit/addons",
      component: _6ba8c7b9,
      name: "dashboard-bookings-id-index-edit-addons___en"
    }, {
      path: "edit/dates",
      component: _8447af22,
      name: "dashboard-bookings-id-index-edit-dates___en"
    }, {
      path: "edit/details",
      component: _4198c12c,
      name: "dashboard-bookings-id-index-edit-details___en"
    }, {
      path: "edit/pending",
      component: _05cab3fe,
      name: "dashboard-bookings-id-index-edit-pending___en"
    }, {
      path: "edit/review",
      component: _3a1a3e5e,
      name: "dashboard-bookings-id-index-edit-review___en"
    }]
  }, {
    path: "/dashboard/rvs/:rvID",
    component: _4535e8e6,
    children: [{
      path: "",
      component: _ce681760,
      name: "dashboard-rvs-rv-Id___en"
    }, {
      path: "addons",
      component: _17543225,
      name: "dashboard-rvs-rv-Id-addons___en"
    }, {
      path: "calendar",
      component: _0ed409a0,
      name: "dashboard-rvs-rv-Id-calendar___en"
    }, {
      path: "details",
      component: _0b5aa440,
      name: "dashboard-rvs-rv-Id-details___en"
    }, {
      path: "earn-more",
      component: _17aec6b0,
      name: "dashboard-rvs-rv-Id-earn-more___en"
    }, {
      path: "insurance",
      component: _1e85cb58,
      name: "dashboard-rvs-rv-Id-insurance___en"
    }, {
      path: "photos",
      component: _39947d73,
      name: "dashboard-rvs-rv-Id-photos___en"
    }, {
      path: "preferences",
      component: _211eba14,
      name: "dashboard-rvs-rv-Id-preferences___en"
    }, {
      path: "pricing",
      component: _3d402078,
      name: "dashboard-rvs-rv-Id-pricing___en"
    }]
  }, {
    path: "/fr/(c|cliquer)/:slug(.+)",
    component: _7db118d8,
    name: "short-link___fr"
  }, {
    path: "/fr/location-vr/:alias",
    component: _457d7bb3,
    name: "rv-rental___fr"
  }, {
    path: "/fr/profil/:id",
    component: _74ea7fba,
    name: "profile-id___fr"
  }, {
    path: "/fr/recovery-password/:code",
    component: _9fcf599a,
    name: "recovery-password-code___fr"
  }, {
    path: "/rv-rental/request-to-book/:alias",
    component: _62a12cd2,
    name: "rv-rental-request-to-book-alias___en",
    children: [{
      path: "additionals",
      component: _7be60e22,
      name: "rv-rental-request-to-book-alias-additionals___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/agreement",
    component: _2585bc28,
    children: [{
      path: "",
      component: _cde2f6aa,
      name: "dashboard-bookings-id-agreement___en"
    }, {
      path: "error",
      component: _4656ba3e,
      name: "dashboard-bookings-id-agreement-error___en"
    }, {
      path: "signature",
      component: _0afd8911,
      name: "dashboard-bookings-id-agreement-signature___en"
    }, {
      path: "success",
      component: _32c80f1c,
      name: "dashboard-bookings-id-agreement-success___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/change-request",
    component: _7ad3fa44,
    children: [{
      path: "",
      component: _71368472,
      name: "dashboard-bookings-id-change-request___en"
    }, {
      path: "accept",
      component: _9baf183a,
      name: "dashboard-bookings-id-change-request-accept___en"
    }, {
      path: "decline",
      component: _d0a63aaa,
      name: "dashboard-bookings-id-change-request-decline___en"
    }, {
      path: "review",
      component: _eb73e39a,
      name: "dashboard-bookings-id-change-request-review___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/insurance-efnol",
    component: _71a08616,
    name: "dashboard-bookings-id-insurance-efnol___en"
  }, {
    path: "/dashboard/bookings/:id/invoice",
    component: _0596656a,
    name: "dashboard-bookings-id-invoice___en"
  }, {
    path: "/dashboard/bookings/:id/payment",
    component: _5674b0b8,
    name: "dashboard-bookings-id-payment___en"
  }, {
    path: "/dashboard/bookings/:id/retry-payment",
    component: _d417f902,
    name: "dashboard-bookings-id-retry-payment___en"
  }, {
    path: "/dashboard/bookings/:id/review",
    component: _25212f2a,
    name: "dashboard-bookings-id-review___en"
  }, {
    path: "/dashboard/bookings/:id/send-request-money",
    component: _ed296e62,
    children: [{
      path: "",
      component: _27cbb812,
      name: "dashboard-bookings-id-send-request-money___en"
    }, {
      path: "dispute",
      component: _97908df8,
      name: "dashboard-bookings-id-send-request-money-dispute___en"
    }, {
      path: "request",
      component: _d1a8dd62,
      name: "dashboard-bookings-id-send-request-money-request___en"
    }, {
      path: "review",
      component: _36be5d48,
      name: "dashboard-bookings-id-send-request-money-review___en"
    }, {
      path: "send",
      component: _02cd9378,
      name: "dashboard-bookings-id-send-request-money-send___en"
    }, {
      path: "sent",
      component: _03af0b88,
      name: "dashboard-bookings-id-send-request-money-sent___en"
    }, {
      path: "success",
      component: _070604c3,
      name: "dashboard-bookings-id-send-request-money-success___en"
    }]
  }, {
    path: "/dashboard/bookings/:id/update-payment",
    component: _82482458,
    name: "dashboard-bookings-id-update-payment___en"
  }, {
    path: "/dashboard/bookings/:id/services/update",
    component: _5f2fa938,
    name: "dashboard-bookings-id-services-update___en"
  }, {
    path: "/(c|click)/:slug(.+)",
    component: _7db118d8,
    name: "short-link___en"
  }, {
    path: "/profile/:id",
    component: _74ea7fba,
    name: "profile-id___en"
  }, {
    path: "/recovery-password/:code",
    component: _9fcf599a,
    name: "recovery-password-code___en"
  }, {
    path: "/rv-rental/:alias",
    component: _457d7bb3,
    name: "rv-rental___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
