import { render, staticRenderFns } from "./maintenance.vue?vue&type=template&id=552af115&scoped=true"
import script from "./maintenance.vue?vue&type=script&lang=js"
export * from "./maintenance.vue?vue&type=script&lang=js"
import style0 from "./maintenance.vue?vue&type=style&index=0&id=552af115&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552af115",
  null
  
)

/* custom blocks */
import block0 from "./maintenance.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoRvezy: require('/azp/_work/1/s/components/logo-rvezy.vue').default})
