import { render, staticRenderFns } from "./webview.vue?vue&type=template&id=0a612552"
import script from "./webview.vue?vue&type=script&lang=js"
export * from "./webview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modals: require('/azp/_work/1/s/components/modals/index.vue').default})
