import { render, staticRenderFns } from "./error.vue?vue&type=template&id=6575cba2"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/pages/error.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fazp%2F_work%2F1%2Fs%2Flayouts%2Ferror.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Default: require('/azp/_work/1/s/components/app/errors/default.vue').default})
