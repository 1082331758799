export const COUNTRY_IDS = {
  CA: 1,
  US: 2,
  ROW: 3 // Rest of the world
}

export const COUNTRY_SHORTS = {
  US: 'US',
  CA: 'CA'
}

export const COUNTRY_CURRENCIES = {
  US: 'USD',
  CA: 'CAD'
}

export const CONVERSION_RATES = {
  [COUNTRY_CURRENCIES.CA]: {
    [COUNTRY_CURRENCIES.CA]: 1,
    [COUNTRY_CURRENCIES.US]: 0.73 // https://rvezy.atlassian.net/browse/RVZ-18109
  },
  [COUNTRY_CURRENCIES.US]: {
    [COUNTRY_CURRENCIES.CA]: 1.36986301365,
    [COUNTRY_CURRENCIES.US]: 1
  }
}

export const UNIT_MEASUREMENT = {
  KM: 'km',
  MILES: 'miles'
}

export const UNIT_SINGLE_MEASUREMENT = {
  KM: 'km',
  MILES: 'mile'
}

const DELIVERY_ADDON_WITHIN_25_KMS_SLUG = 'rv_delivery_(within_50_kms)'
const DELIVERY_ADDON_WITHIN_50_KMS_SLUG = 'rv_delivery_(within_100_kms)'
const DELIVERY_ADDON_WITHIN_100_KMS_SLUG = 'rv_delivery_(within_250_kms)'
const DELIVERY_ADDON_WITHIN_200_KMS_SLUG = 'rv_delivery_(within_400_kms)'

/**
 * @deprecated Only used to support existing bookings
 */
export const DELIVERY_ADDON_SLUGS = [
  DELIVERY_ADDON_WITHIN_25_KMS_SLUG,
  DELIVERY_ADDON_WITHIN_50_KMS_SLUG,
  DELIVERY_ADDON_WITHIN_100_KMS_SLUG,
  DELIVERY_ADDON_WITHIN_200_KMS_SLUG
]

/**
 * @deprecated Only used to support existing bookings
 */
export const DELIVERY_ADDON_PREFIX = 'rv_delivery_'

/**
 * @deprecated
 */
export const DELIVERY_ADDON_DISTANCES = {
  25: {
    slug: DELIVERY_ADDON_WITHIN_25_KMS_SLUG,
    allowedAddOnId: 10,
    km: 25,
    miles: 15,
    defaultPrice: {
      CA: 150,
      US: 120
    }
  },
  50: {
    slug: DELIVERY_ADDON_WITHIN_50_KMS_SLUG,
    allowedAddOnId: 3,
    km: 50,
    miles: 30,
    defaultPrice: {
      CA: 250,
      US: 200
    }
  },
  100: {
    slug: DELIVERY_ADDON_WITHIN_100_KMS_SLUG,
    allowedAddOnId: 9,
    km: 100,
    miles: 60,
    defaultPrice: {
      CA: 450,
      US: 360
    }
  },
  200: {
    slug: DELIVERY_ADDON_WITHIN_200_KMS_SLUG,
    allowedAddOnId: 33,
    km: 200,
    miles: 120,
    defaultPrice: {
      CA: 640,
      US: 480
    }
  }
}

export const PROVINCE_TAX_ENABLED = {
  ['british columbia']: true,
  ['saskatchewan']: true,
  ['quebec']: true
}

export const ALLOWED_IMAGE_TYPES = ['jpg', 'jpeg', 'png']

export const CALENDAR_UNAVAILABLE = 'calendar_unavailable'

export const MINIMUM_RV_PHOTOS = 1
export const RECOMMENDED_NUMBER_RV_PHOTOS = 5

// Delivery fields
export const DELIVERY_DISTANCE_MIN = 1
export const DELIVERY_DISTANCE_MAX = 2000
export const DELIVERY_MINIMUM_COST_MIN = 5
export const DELIVERY_COST_PER_DISTANCE_MIN = 0.01

export const ACCOMMODATIONS = {
  BedQueen: 'BedQueen',
  BedKing: 'BedKing',
  BedBunkTwin: 'BedBunkTwin',
  BedBunkDouble: 'BedBunkDouble',
  BedDinette: 'BedDinette',
  BedSofa: 'BedSofa'
}

export const INVALID_DATE_ERROR_STRING = 'Invalid Date'

export const FLEXIBLE_CHECK_IN_OUT = -1

export const RECEIPT_HIGHLIGHT_LINES = {
  DATES: 'dates',
  ADDONS: 'addons'
}

export const US_THANKSGIVING_DATES = {
  start: '2024-11-28',
  end: '2024-12-01'
}

export const CANADA_THANKSGIVING_DATES = {
  start: '2024-10-11',
  end: '2024-10-14'
}

export const RV_OLDEST_LISTING_YEAR = 1970
export const RV_MAX_MOTORHOME_AGE = 15
export const RV_MAX_DEFAULT_AGE = 20

export const TIME_FORMAT = {
  Hour24: 'HH:mm',
  Hour12: 'hh:mm a'
}
