import { render, staticRenderFns } from "./avatar-dropdown.vue?vue&type=template&id=f832fafa"
import script from "./avatar-dropdown.vue?vue&type=script&lang=js"
export * from "./avatar-dropdown.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZAvatar: require('/azp/_work/1/s/components/style-guide/avatar.vue').default,ZDropdownItem: require('/azp/_work/1/s/components/style-guide/dropdown-item.vue').default,ZDropdown: require('/azp/_work/1/s/components/style-guide/dropdown.vue').default})
