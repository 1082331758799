import { render, staticRenderFns } from "./close.vue?vue&type=template&id=4f014923"
import script from "./close.vue?vue&type=script&lang=js"
export * from "./close.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Fa: require('/azp/_work/1/s/components/fa.vue').default,ZCircleButton: require('/azp/_work/1/s/components/style-guide/circle-button.vue').default})
