import { render, staticRenderFns } from "./desktop.vue?vue&type=template&id=3972e20e&scoped=true"
import script from "./desktop.vue?vue&type=script&lang=js"
export * from "./desktop.vue?vue&type=script&lang=js"
import style0 from "./desktop.vue?vue&type=style&index=0&id=3972e20e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3972e20e",
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/app.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fazp%2F_work%2F1%2Fs%2Fcomponents%2Fapp%2Fheader-nav%2Fdesktop.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZNavItem: require('/azp/_work/1/s/components/style-guide/nav-item.vue').default,ButtonRentAnRv: require('/azp/_work/1/s/components/button/rent-an-rv.vue').default,AvatarDropdown: require('/azp/_work/1/s/components/app/header-nav/avatar-dropdown.vue').default,ZNavbarNav: require('/azp/_work/1/s/components/style-guide/navbar-nav.vue').default})
