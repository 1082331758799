import { render, staticRenderFns } from "./card.vue?vue&type=template&id=eb553f62"
import script from "./card.vue?vue&type=script&lang=js"
export * from "./card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ZCardBody: require('/azp/_work/1/s/components/style-guide/card-body.vue').default})
